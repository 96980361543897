<template>
  <div class="fundAccountChecke">
    <div class="tabs">
      <el-tabs v-model="channel" type="card" @tab-click="renderData">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="explain">
      <header><i class="el-icon-warning"></i> 数据说明</header>
      <ol>
        <li>平台将在次日10点生成前一天的对账单，建议在11点后查看。</li>
        <li>
          微信订单数据来源：微信支付商户平台的交易账单，微信分账数据来源：微信支付商户平台的分账账单
        </li>
        <li>收支净额=订单金额-退款金额-分账金额+分账回退金额</li>
        <li>退款金额包含订单退款、定制客运售后退款</li>
      </ol>
    </div>
    <div class="form-tab">
      <span>对账周期：</span>
      <el-date-picker
        v-model="month"
        type="month"
        format="yyyy-MM"
        value-format="yyyy-MM"
        :picker-options="pickerOptions"
        placeholder="请选择对账周期"
        :clearable="false"
      >
      </el-date-picker>
      <el-button type="primary" size="small" @click="renderData"
        >查询</el-button
      >
    </div>
    <div class="table">
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :span-method="objectSpanMethod"
        operation-width="150"
      >
        <template slot-scope="scope">
          <div>
            <template v-if="listFind('交易账单')">
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="clickToPage('dealBill', scope.scopeRow)"
                >交易账单</el-button
              >
            </template>
            <template v-if="listFind('分账账单')">
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="clickToPage('divisionBill', scope.scopeRow)"
                >分账账单</el-button
              >
            </template>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import {
  getCurrentUserChannelInfoAPI,
  fundStatementSummaryPage,
} from "@/api/lib/api.js";
import moment from "moment";
export default {
  data() {
    return {
      channelList: [],
      channel: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableData: [],
      titleName: [
        {
          title: "日期",
          props: "date",
          SpecialJudgment: (res) => res.slice(0, 10),
        },
        {
          title: "核对项",
          props: "checkItem",
        },
        {
          title: "订单金额",
          props: "payPrice",
        },
        {
          title: "退款金额",
          props: "refundPrice",
        },
        {
          title: "分账金额",
          props: "payLedgerPrice",
        },
        {
          title: "分账回退金额",
          props: "refundLedgerPrice",
        },
        {
          title: "收支净额",
          props: "netIncome",
          SpecialJudgment: (res) => (res !== null ? res : ""),
        },
        {
          title: "对账结果",
          props: "result",
        },
        {
          title: "备注",
          props: "remark",
        },
      ],
      month: moment(new Date()).subtract(1, "month").format("YYYY-MM"),
    };
  },
  mounted() {
    this.getQueryChannelList();
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.channel = res.data[0]?.channelNo;
          this.renderData();
        }
      });
    },
    // 表格数据渲染
    renderData() {
      const data = {
        channel: this.channel,
        month: this.month,
      };
      fundStatementSummaryPage(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data || [];
        }
      });
    },
    //合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 7, 8, 9].includes(columnIndex)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    clickToPage(page, row) {
      const date = row.date.slice(0, 10);
      this.$router.push(
        `/projectTravel/${page}?channelNo=${this.channel}&date=${date}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.fundAccountChecke {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .form-tab {
    background-color: #f7f8f9;
    padding: 16px;
    display: flex;
    align-items: center;
    .el-date-editor {
      width: 200px;
      margin-right: 20px;
    }
  }
  .table {
    margin-top: 10px;
    overflow: auto;
    flex: 1;
  }
}
</style>
